<template>
  <div id="app" class="container my-5">
    <svg height="0" width="0">
      <defs>
        <linearGradient id="success-gradient" x1="50%" y1="0" x2="50%" y2="1">
          <stop offset="0" stop-color="#19af55" stop-opacity="0.4"></stop>
          <stop offset="1" stop-color="white" stop-opacity="0.4"></stop>
        </linearGradient>
        <linearGradient id="danger-gradient" x1="50%" y1="0" x2="50%" y2="1">
          <stop offset="0" stop-color="#dc3545" stop-opacity="0.4"></stop>
          <stop offset="1" stop-color="white" stop-opacity="0.4"></stop>
        </linearGradient>
      </defs>
    </svg>

    <div class="row" v-if="crypto24h">
      <div class="col-md-6">
        <div data-key="top-crypto-change-24h">
          <Header title="Crypto Market" subtitle="24 hours" />
          <Market
            :list="customList"
            type="cryptos"
            prefix="daily"
            :rate="USDINR"
            duration="24h"
            calculate
          />
        </div>
      </div>
      <div class="col-md-6">
        <div data-key="top-crypto-change-7d">
          <Header title="Crypto Market" subtitle="7 days" />
          <Market
            :list="customList"
            type="cryptos"
            prefix="weekly"
            :rate="USDINR"
            duration="7d"
            calculate
          />
        </div>
      </div>
    </div>

    <div class="row" v-if="crypto24h">
      <div class="col-md-6">
        <div data-key="top-gainers-crypto-24h">
          <Header title="Top Gainers in Crypto Market" subtitle="24 hours" />
          <Market
            :list="crypto24h.top"
            type="cryptos"
            prefix="daily-top"
            :rate="USDINR"
            duration="24h"
          />
        </div>
        <div class="mt-4">
          <p v-for="item in crypto24h.top" :key="item.name">
            🚀 {{ item.name }} ${{ item.ticker }} rose by
            {{ item.change | percentage }} in last 24 hours.
          </p>
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div data-key="top-losers-stock-market-24h">
          <Header title="Top Losers in Crypto Market" subtitle="24 hours" />
          <Market :list="crypto24h.bottom" type="cryptos" prefix="daily-bottom" :rate="USDINR" duration="24h" />
        </div>
      </div> -->
    </div>

    <div class="row" v-if="crypto">
      <div class="col-md-6">
        <div data-key="top-gainers-crypto-7d">
          <Header title="Top Gainers in Crypto Market" subtitle="7 days" />
          <Market
            :list="crypto.top"
            type="cryptos"
            prefix="weekly-top"
            :rate="USDINR"
            duration="7d"
          />
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div data-key="top-losers-crypto-7d">
          <Header title="Top Losers in Crypto Market" subtitle="7 days" />
          <Market :list="crypto.bottom" type="cryptos" prefix="weekly-bottom" :rate="USDINR" duration="7d" />
        </div>
      </div> -->
    </div>

    <div class="row" v-if="stocks">
      <div class="col-md-6">
        <div data-key="top-gainers-stock-market-7d">
          <Header title="Top Gainers in Stock Market" subtitle="7 days" />
          <Market
            :list="stocks.top"
            type="stocks"
            prefix="weekly-top"
            duration="7d"
          />
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div data-key="top-losers-stock-market-7d">
          <Header title="Top Losers in Stock Market" subtitle="7 days" />
          <Market :list="stocks.bottom" type="stocks" prefix="weekly-bottom" duration="7d" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Market from '@/components/Market'
import formatter from '@/mixins/formatter'

export default {
  mixins: [formatter],
  components: {
    Header,
    Market
  },
  data() {
    return {
      stocks: false,
      crypto: false,
      crypto24h: false,
      USDINR: 0
    }
  },
  computed: {
    customList() {
      return [
        {
          id: 1,
          name: 'Bitcoin',
          ticker: 'BTC',
          image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png'
        },
        {
          id: 1027,
          name: 'Ethereum',
          ticker: 'ETH',
          image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png'
        },
        {
          id: 3890,
          name: 'Polygon',
          ticker: 'MATIC',
          image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png'
        },
        {
          id: 1839,
          name: 'Binance Coin',
          ticker: 'BNB',
          image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png'
        },
        {
          id: 2010,
          name: 'Cardano',
          ticker: 'ADA',
          image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png'
        }
      ]
    }
  },
  async created() {
    const [stocks, crypto, crypto24h, USDINR] = await Promise.all([
      this.$http('/market/stocks/topbottom?duration=7d').then(
        (res) => res.data.data
      ),
      this.$http('/market/cryptos/topbottom?duration=7d&range=60').then(
        (res) => res.data.data
      ),
      this.$http('/market/cryptos/topbottom?duration=24h&range=60').then(
        (res) => res.data.data
      ),
      this.$http(
        'https://api.coingecko.com/api/v3/simple/price?ids=usd-coin&vs_currencies=inr'
      ).then((res) => res.data['usd-coin'].inr)
    ])

    this.USDINR = USDINR
    this.stocks = stocks
    this.crypto = crypto
    this.crypto24h = crypto24h
  }
}
</script>

<style lang="scss">
body {
  font-family: 'Inter', sans-serif !important;
}

// thead {
//   display: none;
// }

// tbody > tr:first-child {
//   td {
//     border: 0!important;
//   }
// }

.table-responsive {
  border-radius: 0.25rem;
}

.ss {
  thead {
    display: none;
  }
}

.ss-noborder {
  td,
  th {
    border: 0 !important;
  }
}

table {
  th:not(:first-child) {
    width: 1%;
  }

  th:last-child,
  td:last-child {
    white-space: nowrap;
  }
}

td,
th {
  vertical-align: middle !important;
  line-height: 1.45 !important;
}

td {
  .icon {
    height: 10px;
    display: inline-block;
    margin-right: 2px;
    transform: rotateZ(180deg);
  }

  .d-flex {
    align-items: center;

    img {
      height: 32px;
      margin-right: 12px;
    }
  }
}

.text-success {
  .icon {
    fill: #28a745;
  }
}

.text-danger {
  .icon {
    fill: #dc3545;
    transform: rotateZ(0deg);
  }
}

.row + .row {
  margin-top: 2rem;
}
</style>
